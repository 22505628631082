const traceCreators = require('./logger/traceCreators');

function prefetchUserCode(
  wixCodeScripts,
  wixCodeApi,
  userConsole,
  appLogger,
  fedopsLogger,
) {
  const traceConfig = traceCreators.loadUserCode();
  wixCodeScripts.forEach((script) => {
    try {
      return appLogger.traceSync(traceConfig, () => {
        fedopsLogger.interactionStarted(traceConfig.actionName);

        wixCodeApi.environment.network.prefetchScript(script.url);

        fedopsLogger.interactionEnded(traceConfig.actionName);
      });
    } catch (e) {
      appLogger.error(e);
      userConsole.error(e);
    }
  });
}

module.exports.prefetchUserCode = prefetchUserCode;
